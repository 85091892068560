/**
 * NavBar.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Image, Icon, Dropdown } from 'semantic-ui-react';
import { history } from '../history';
import Logo from '../img/download.png';

const languageOptions = [
	{ key: 'en', text: 'English', value: 'English' },
	{ key: 'en', text: 'Oshiwambo', value: 'Oshiwambo' },
	{ key: 'en', text: 'Herero', value: 'Herero' },
	{ key: 'de', text: 'Damara/Nama', value: 'Deutsch' },
	{ key: 'fr', text: 'Rukwangali', value: 'Français' },
	// Add more languages as needed
];

const NavBar = () => {
	const [activeItem, setActiveItem] = useState('Home');
	const [selectedLanguage, setSelectedLanguage] = useState('English');

	const handleItemClick = (e, item) => {
		history.push(item.to);
		setActiveItem(item.name);
		window.location.reload();
	};

	return (
		<Menu fixed="top" borderless>
			<Menu.Menu position="left">
				{/* Logo on the far left */}
				<Menu.Item>
					<Image size="mini" src={Logo} />
				</Menu.Item>
			</Menu.Menu>

			{/* Centered Navmenu */}
			<div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
				<Menu.Item
					as={Link}
					to="/"
					name="Home"
					active={activeItem === 'Home'}
					onClick={handleItemClick}
				/>
				<Menu.Item
					as={Link}
					to="/streaming"
					name="Streaming"
					active={activeItem === 'Streaming'}
					onClick={handleItemClick}
				/>
				<Menu.Item
					as={Link}
					to="/info-center"
					name="Info Center"
					active={activeItem === 'Info Center'}
					onClick={handleItemClick}
				/>
				<Menu.Item
					as={Link}
					to="/how-it-works"
					name="How it Works"
					active={activeItem === 'How it Works'}
					onClick={handleItemClick}
				/>
				<Menu.Item
					as={Link}
					to="/apply"
					name="Apply"
					active={activeItem === 'Apply'}
					onClick={handleItemClick}
				/>
				<Menu.Item
					as={Link}
					to="/faqs"
					name="FAQs"
					active={activeItem === 'FAQs'}
					onClick={handleItemClick}
				/>
			</div>

			<Menu.Menu position="right">
				<Menu.Item as={Link} to="/admin" name="Admin" onClick={handleItemClick}>
					<Icon name="user" />
					John Doe
				</Menu.Item>
				<Dropdown
					item
					simple
					text={selectedLanguage}
					options={languageOptions}
					onChange={(e, { value }) => setSelectedLanguage(value)}
				/>
			</Menu.Menu>
		</Menu>
	);
};

export default NavBar;

/**
 * HomePage.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useState } from 'react';
import {
	Container,
	Input,
	Button,
	Menu,
	Card,
	Image,
	Modal,
	Icon,
	Header,
	Embed,
	Comment,
	Form,
} from 'semantic-ui-react';
import Slider from 'react-slick';

const HomePage = () => {
	const [activeItem, setActiveItem] = useState('HEALTH');
	const [expandedPost, setExpandedPost] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');
	const [comments, setComments] = useState([
		'The vaccination drive in our community has been really efficient. Got my second dose today!',
		"I've been working from home since the pandemic started. It's become my new normal.",
		'Does anyone have updated information on the travel restrictions?',
		'Just read that the new variant is more transmissible but less severe. Still, better to stay cautious.',
		'Local businesses are really suffering due to the lockdowns. Make sure to support them however you can.',
	]);
	const [newComment, setNewComment] = useState('');

	const categories = ['HEALTH', 'SPORTS', 'NEWS', 'EVENTS', 'RANDOM', 'FOR ME'];

	const posts = [
		// Example from Ministry of Health
		{
			id: 9,
			category: 'HEALTH',
			title: 'New Outbreak in Khomas',
			shortDesc: 'Latest updates on COVID-19...',
			longDesc:
				'The Ministry of Health updates the nation on the COVID-19 situation.',
			publishedBy: 'MOH',
			images: [
				'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsv9jvci7FEQwbusnciTWuEG5iiD0NzWZzoQ&usqp=CAU',
				'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpKOwGlmWhJg2h9a_5-IkO3r8Zr2ZvP38s0g&usqp=CAU',
			],
			video: null,
		},
		{
			id: 10,
			category: 'HEALTH',
			title: 'COVID-19 in Namibia',
			shortDesc: 'Latest updates on COVID-19...',
			longDesc:
				'The Ministry of Health updates the nation on the COVID-19 situation.',
			publishedBy: 'MOH',
			images: [
				'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNSKGOa6je9ER6uQgLNIO40yV5zJTrhmg49w&usqp=CAU',
				'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_xIv7Y2gjHAQlOTk8HBki2sBvhnWlsYJYJQ&usqp=CAU',
			],
			video: null,
		},
		{
			id: 10,
			category: 'HEALTH',
			title: 'How to treat COVID',
			shortDesc: 'Short video to treat covid at home...',
			longDesc:
				'The Ministry of Health updates the nation on the COVID-19 situation.',
			publishedBy: 'MOH',
			images: [
				'https://via.placeholder.com/150',
				'https://via.placeholder.com/160',
			],
			video: 'https://www.youtube.com/watch?v=FAW-qDsL6Bs',
		},
		{
			id: 15,
			category: 'HEALTH',
			title: 'HIV/AIDS News Namibia',
			shortDesc: 'Latest updates on HIV/AIDS...',
			longDesc:
				'The Ministry of Health updates the nation on the COVID-19 situation.',
			publishedBy: 'MOH',
			images: [
				'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR898U2keQU5T1-Y78NsZ_xVkUyVdF0gCTgow&usqp=CAU',
				'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5WVIz6A6gIIgbyxQDtSxo8RJugUhMloceVQ&usqp=CAU',
			],
			video: null,
		},
		{
			id: 18,
			category: 'HEALTH',
			title: 'COVID-19 in Namibia',
			shortDesc: 'Latest updates on COVID-19...',
			longDesc:
				'The Ministry of Health updates the nation on the COVID-19 situation.',
			publishedBy: 'MOH',
			images: [
				'https://via.placeholder.com/150',
				'https://via.placeholder.com/160',
			],
			video: null,
		},
		// Ministry of Health
		{
			id: 1,
			category: 'HEALTH',
			title: 'COVID-19 in Namibia',
			shortDesc: 'Latest updates on COVID-19...',
			longDesc:
				'The Ministry of Health updates the nation on the COVID-19 situation.',
			publishedBy: 'MOH',
			images: [
				'https://via.placeholder.com/150',
				'https://via.placeholder.com/160',
			],
			video: null,
		},
		// Ministry of Education
		{
			id: 2,
			category: 'NEWS',
			title: 'New Education Policy',
			shortDesc: 'Introduction of a new education policy...',
			longDesc:
				'The Ministry of Education is implementing a new policy to improve educational standards.',
			publishedBy: 'MOE',
			images: ['https://via.placeholder.com/150'],
			video: 'abc123', // Example YouTube video ID
		},
		// Ministry of Sports
		{
			id: 3,
			category: 'SPORTS',
			title: 'National Sports Day',
			shortDesc: 'Celebrating National Sports Day...',
			longDesc:
				'The Ministry of Sports announces activities for the upcoming National Sports Day.',
			publishedBy: 'MOS',
			images: ['https://via.placeholder.com/150'],
			video: 'https://www.youtube.com/watch?v=FAW-qDsL6Bs',
		},
		// Ministry of Finance
		{
			id: 4,
			category: 'NEWS',
			title: 'Budget 2023 Announced',
			shortDesc: 'Details about the 2023 budget...',
			longDesc:
				'The Ministry of Finance has announced the budget for the fiscal year 2023.',
			publishedBy: 'MOF',
			images: [
				'https://via.placeholder.com/150',
				'https://via.placeholder.com/160',
			],
			video: 'xyz789', // Example YouTube video ID
		},
		// Ministry of Agriculture
		{
			id: 5,
			category: 'NEWS',
			title: 'Agriculture Subsidy',
			shortDesc: 'New subsidy plans for farmers...',
			longDesc:
				'The Ministry of Agriculture introduces new subsidy plans for farmers.',
			publishedBy: 'MOA',
			images: ['https://via.placeholder.com/150'],
			video: null,
		},
		// Ministry of Environment
		{
			id: 6,
			category: 'EVENTS',
			title: 'Climate Change Summit',
			shortDesc: 'Upcoming Climate Change Summit...',
			longDesc:
				'The Ministry of Environment will host an international summit on climate change.',
			publishedBy: 'MOE',
			images: ['https://via.placeholder.com/150'],
			video: 'lmn456', // Example YouTube video ID
		},
		// Ministry of Transport
		{
			id: 7,
			category: 'NEWS',
			title: 'New Public Transport System',
			shortDesc: 'Introduction of a new public transport system...',
			longDesc:
				'The Ministry of Transport is introducing a new, efficient public transport system.',
			publishedBy: 'MOT',
			images: ['https://via.placeholder.com/150'],
			video: null,
		},
		// Ministry of Tourism
		{
			id: 8,
			category: 'RANDOM',
			title: 'Tourism Recovery Plan',
			shortDesc: 'Plans to boost the tourism industry...',
			longDesc:
				'The Ministry of Tourism announces a recovery plan to boost the tourism industry.',
			publishedBy: 'MOTour',
			images: [
				'https://via.placeholder.com/150',
				'https://via.placeholder.com/160',
			],
			video: null,
		},
		// Ministry of Energy
		{
			id: 9,
			category: 'NEWS',
			title: 'Renewable Energy Initiatives',
			shortDesc: 'Initiatives to promote renewable energy...',
			longDesc:
				'The Ministry of Energy unveils new initiatives to promote renewable energy.',
			publishedBy: 'MOE',
			images: ['https://via.placeholder.com/150'],
			video: 'ijk101', // Example YouTube video ID
		},

		// Add 8 more examples from different ministries
		// ...
	];

	const handleItemClick = (name) => setActiveItem(name);

	const filteredPosts = posts.filter(
		(post) =>
			post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
			post.shortDesc.toLowerCase().includes(searchTerm.toLowerCase()) ||
			post.longDesc.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
	};

	return (
		<Container textAlign="center">
			<Input
				action={
					<Button color="blue">
						<Icon name="search" />
					</Button>
				}
				placeholder="Search..."
				style={{ marginTop: '2em', width: '50%' }}
				onChange={(e) => setSearchTerm(e.target.value)}
			/>
			<Menu tabular style={{ marginTop: '2em' }}>
				{categories.map((category) => (
					<Menu.Item
						name={category}
						active={activeItem === category}
						onClick={() => handleItemClick(category)}
					/>
				))}
			</Menu>
			<Card.Group centered>
				{filteredPosts
					.filter((post) => post.category === activeItem)
					.map((post) => (
						<Modal
							trigger={
								<Card
									color="blue"
									key={post.id}
									onClick={() => setExpandedPost(post.id)}
								>
									{post.video ? (
										<Embed id={post.video} source="youtube" />
									) : (
										<Slider {...settings}>
											{post.images.map((image, index) => (
												<Image key={index} src={image} wrapped ui={true} />
											))}
										</Slider>
									)}
									<Card.Content>
										<Card.Header>{post.title}</Card.Header>
										<Card.Description>{post.shortDesc}</Card.Description>
									</Card.Content>
									<Card.Content extra>
										<Button.Group>
											<Button icon>
												<Icon name="backward" />
											</Button>
											<Button icon>
												<Icon name="play" />
											</Button>
											<Button icon>
												<Icon name="forward" />
											</Button>
										</Button.Group>
									</Card.Content>
									<Card.Content extra>
										<a>
											<Icon name="user" />
											Published by: {post.publishedBy}
										</a>
									</Card.Content>
								</Card>
							}
						>
							<Modal.Header>{post.title}</Modal.Header>
							<Modal.Content>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									{/* Left-aligned Preview Image and Long Description */}
									<div style={{ flex: '2', paddingRight: '20px' }}>
										<Image src={post.images[0]} size="medium" />
										<p>{post.longDesc}</p>
									</div>

									{/* Right-aligned Comment Bubbles */}
									<div
										style={{
											flex: '1',
											borderLeft: '1px solid #ccc',
											paddingLeft: '20px',
										}}
									>
										<Comment.Group>
											<Header as="h3" dividing>
												Comments
											</Header>

											{comments.map((comment, index) => (
												<Comment key={index}>
													<Comment.Avatar src="https://react.semantic-ui.com/images/avatar/small/matt.jpg" />
													<Comment.Content>
														<Comment.Author as="a">Random User</Comment.Author>
														<Comment.Metadata>
															<div>Today at 5:42PM</div>
														</Comment.Metadata>
														<Comment.Text>{comment}</Comment.Text>
													</Comment.Content>
												</Comment>
											))}

											<Form reply>
												<Form.TextArea
													value={newComment}
													onChange={(e) => setNewComment(e.target.value)}
												/>
												<Button
													content="Add Reply"
													labelPosition="left"
													icon="edit"
													primary
													onClick={() => {
														setComments([...comments, newComment]);
														setNewComment('');
													}}
												/>
											</Form>
										</Comment.Group>
									</div>
								</div>
							</Modal.Content>
						</Modal>
					))}
			</Card.Group>
		</Container>
	);
};

export default HomePage;

/**
 * AdminHomePage.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useState } from 'react';
import {
	Container,
	Button,
	Menu,
	Card,
	Image,
	Modal,
	Icon,
	Embed,
	Message,
	Form,
	Dropdown,
	Comment,
	Header,
	Segment,
} from 'semantic-ui-react';
import Slider from 'react-slick';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const posts = [
	// Example from Ministry of Health
	{
		id: 9,
		category: 'HEALTH',
		title: 'New Outbreak in Khomas',
		shortDesc: 'Latest updates on COVID-19...',
		longDesc:
			'The Ministry of Health updates the nation on the COVID-19 situation.',
		publishedBy: 'MOH',
		images: [
			'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsv9jvci7FEQwbusnciTWuEG5iiD0NzWZzoQ&usqp=CAU',
			'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpKOwGlmWhJg2h9a_5-IkO3r8Zr2ZvP38s0g&usqp=CAU',
		],
		video: null,
	},
	{
		id: 10,
		category: 'HEALTH',
		title: 'COVID-19 in Namibia',
		shortDesc: 'Latest updates on COVID-19...',
		longDesc:
			'The Ministry of Health updates the nation on the COVID-19 situation.',
		publishedBy: 'MOH',
		images: [
			'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNSKGOa6je9ER6uQgLNIO40yV5zJTrhmg49w&usqp=CAU',
			'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_xIv7Y2gjHAQlOTk8HBki2sBvhnWlsYJYJQ&usqp=CAU',
		],
		video: null,
	},
	{
		id: 10,
		category: 'HEALTH',
		title: 'How to treat COVID',
		shortDesc: 'Short video to treat covid at home...',
		longDesc:
			'The Ministry of Health updates the nation on the COVID-19 situation.',
		publishedBy: 'MOH',
		images: [
			'https://via.placeholder.com/150',
			'https://via.placeholder.com/160',
		],
		video: 'https://www.youtube.com/watch?v=FAW-qDsL6Bs',
	},
];

const AdminHomePage = () => {
	const [activeItem, setActiveItem] = useState('HEALTH');
	const [selectedPost, setSelectedPost] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [successMessage, setSuccessMessage] = useState(false);

	const [expandedPost, setExpandedPost] = useState(null);
	const [newPublication, setNewPublication] = useState({
		// ... other fields ...
		tags: [],
	});

	const [searchTerm, setSearchTerm] = useState('');
	const [comments, setComments] = useState([
		'The vaccination drive in our community has been really efficient. Got my second dose today!',
		"I've been working from home since the pandemic started. It's become my new normal.",
		'Does anyone have updated information on the travel restrictions?',
		'Just read that the new variant is more transmissible but less severe. Still, better to stay cautious.',
		'Local businesses are really suffering due to the lockdowns. Make sure to support them however you can.',
	]);
	const [newComment, setNewComment] = useState('');

	const [tagOptions, setTagOptions] = useState([
		{ key: 'covid', text: 'COVID-19', value: 'covid' },
		{ key: 'health', text: 'Health', value: 'health' },
		{ key: 'sports', text: 'Sports', value: 'sports' },
		// ... more tags
	]);

	const categories = ['HEALTH', 'SPORTS', 'NEWS', 'EVENTS', 'RANDOM'];
	const priorityOptions = ['High', 'Medium', 'Low'];

	// Data for the Bar Chart (Public Reactions)

	const publicReactionData = {
		labels: ['Positive', 'Neutral', 'Negative'],
		datasets: [
			{
				label: 'Public Reactions to Initiative X',
				data: [12, 19, 3],
				backgroundColor: [
					'rgba(75, 192, 192, 0.6)',
					'rgba(255, 206, 86, 0.6)',
					'rgba(255, 99, 132, 0.6)',
				],
			},
		],
	};

	// Data for the Doughnut Chart (Ministry Budget Allocation)
	const budgetData = {
		labels: ['Health', 'Education', 'Infrastructure'],
		datasets: [
			{
				data: [50, 30, 20],
				backgroundColor: ['blue', 'green', 'red'],
			},
		],
	};

	// Data for the Line Chart (Monthly Post Views)
	const postViewsData = {
		labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
		datasets: [
			{
				label: 'Monthly Post Views',
				data: [12, 19, 3, 5, 2],
				borderColor: 'purple',
				fill: false,
			},
		],
	};

	const options = {
		scales: {
			x: {
				type: 'category', // specify the scale type
				labels: ['Positive', 'Neutral', 'Negative'], // your labels
			},
		},
	};

	const handleItemClick = (name) => setActiveItem(name);

	const handleChange = (e, { name, value }) =>
		setNewPublication({ ...newPublication, [name]: value });

	const handlePublish = () => {
		console.log('Publishing:', newPublication);
		// TODO: Publish the post

		setSuccessMessage(true);

		setTimeout(() => {
			setModalOpen(false);
		}, 3000);
	};

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
	};

	return (
		<Container textAlign="center" style={{ marginTop: '20px' }}>
			{/* Graphs Section */}
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-around',
					marginBottom: '20px',
				}}
			>
				{/* <Bar data={publicReactionData} width={100} height={50} /> */}
				{/* <Doughnut data={budgetData} width={100} height={50} /> */}
				{/* <Line data={postViewsData} width={100} height={50} /> */}
			</div>

			<h1>Ministry of Health - Publications</h1>
			<Menu tabular style={{ marginTop: '2em' }}>
				{categories.map((category) => (
					<Menu.Item
						name={category}
						active={activeItem === category}
						onClick={() => handleItemClick(category)}
					/>
				))}
				<Menu.Item position="right">
					<Button color="green" onClick={() => setModalOpen(true)}>
						NEW PUBLICATION
					</Button>
				</Menu.Item>
			</Menu>

			<Card.Group centered>
				{posts
					.filter((post) => post.category === activeItem)
					.map((post) => (
						<Modal
							trigger={
								<Card
									color="blue"
									key={post.id}
									onClick={() => setExpandedPost(post.id)}
								>
									{post.video ? (
										<Embed id={post.video} source="youtube" />
									) : (
										<Slider {...settings}>
											{post.images.map((image, index) => (
												<Image key={index} src={image} wrapped ui={true} />
											))}
										</Slider>
									)}
									<Card.Content>
										<Card.Header>{post.title}</Card.Header>
										<Card.Description>{post.shortDesc}</Card.Description>
									</Card.Content>
									<Card.Content extra>
										<a>
											<Icon name="user" />
											Published by: {post.publishedBy}
										</a>
									</Card.Content>
									<Card.Content extra>
										<Button.Group>
											<Button icon>
												<Icon name="pencil alternate" color="blue" />
											</Button>
											<Button icon>
												<Icon name="" />
											</Button>
											<Button icon>
												<Icon name="trash" color="red" />
											</Button>
										</Button.Group>
									</Card.Content>
								</Card>
							}
						>
							<Modal.Header>{post.title}</Modal.Header>
							<Modal.Content>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									{/* Left-aligned Preview Image and Long Description */}
									<div style={{ flex: '2', paddingRight: '20px' }}>
										<Image src={post.images[0]} size="medium" />
										<p>{post.longDesc}</p>
									</div>

									{/* Right-aligned Comment Bubbles */}
									<div
										style={{
											flex: '1',
											borderLeft: '1px solid #ccc',
											paddingLeft: '20px',
										}}
									>
										<Comment.Group>
											<Header as="h3" dividing>
												Comments
											</Header>

											{comments.map((comment, index) => (
												<Comment key={index}>
													<Comment.Avatar src="https://react.semantic-ui.com/images/avatar/small/matt.jpg" />
													<Comment.Content>
														<Comment.Author as="a">Random User</Comment.Author>
														<Comment.Metadata>
															<div>Today at 5:42PM</div>
														</Comment.Metadata>
														<Comment.Text>{comment}</Comment.Text>
													</Comment.Content>
												</Comment>
											))}
										</Comment.Group>
									</div>
								</div>
							</Modal.Content>
						</Modal>
					))}
			</Card.Group>

			<Modal open={modalOpen} onClose={() => setModalOpen(false)}>
				<Modal.Header>New Publication</Modal.Header>
				<Modal.Content>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						{/* Form on the Left */}
						<div style={{ flex: '1' }}>
							<Form>
								<Form.Input
									fluid
									name="title"
									label="Title"
									placeholder="Title"
									onChange={handleChange}
								/>
								<Form.TextArea
									name="description"
									label="Description"
									placeholder="Description..."
									onChange={handleChange}
								/>
								<Form.Field>
									<label>Category</label>
									<Dropdown
										fluid
										selection
										options={categories.map((c) => ({
											key: c,
											text: c,
											value: c,
										}))}
										name="category"
										onChange={handleChange}
									/>
								</Form.Field>
								<Form.Field>
									<label>Priority</label>
									<Dropdown
										fluid
										selection
										options={priorityOptions.map((p) => ({
											key: p,
											text: p,
											value: p,
										}))}
										name="priority"
										onChange={handleChange}
									/>
								</Form.Field>
								<Form.Field>
									<label>Tags</label>
									<Dropdown
										clearable
										fluid
										multiple
										search
										selection
										allowAdditions
										options={tagOptions}
										name="tags"
										onAddItem={(e, { value }) => {
											setTagOptions([
												...tagOptions,
												{ key: value, text: value, value },
											]);
										}}
										onChange={(e, { value }) =>
											setNewPublication({ ...newPublication, tags: value })
										}
									/>
								</Form.Field>
								<Form.Input
									type="file"
									label="Media Upload"
									accept="image/*,video/*"
									name="media"
									onChange={handleChange}
								/>
							</Form>
							<br />
							<br />
							<Button color="green" onClick={handlePublish}>
								Publish
							</Button>
						</div>

						{/* Card Preview on the Right */}
						<div style={{ flex: '1', marginLeft: '20px' }}>
							<Segment>
								<h4>Preview</h4>
								<Card>
									<Slider {...settings}>
										<Image
											src={
												'https://i0.wp.com/nakara-namibia.com/wp-content/uploads/2021/09/placeholder-4.png?fit=1200%2C800&ssl=1'
											}
											wrapped
											ui={true}
										/>
									</Slider>
									{/* Add your card preview here based on newPublication state */}
									<Card.Content>
										<Card.Header>{newPublication.title || 'Title'}</Card.Header>
										<Card.Description>
											{newPublication.description || 'Description...'}
										</Card.Description>
									</Card.Content>
									<Card.Content extra>
										{newPublication.priority} Priority
									</Card.Content>
								</Card>
							</Segment>
						</div>
					</div>
				</Modal.Content>

				{successMessage && (
					<Message
						success
						header="Publication Successful"
						content="Your new publication has been successfully created."
					/>
				)}
			</Modal>

			{/* Your existing card group and posts */}
		</Container>
	);
};

export default AdminHomePage;

import React from 'react';
import { Card, Icon, Grid, Button, Header } from 'semantic-ui-react';

const Applications = () => {
	const applicationTopics = [
		{
			title: 'Employment',
			description: 'Kickstart your career with the right job.',
			iconName: 'briefcase',
		},
		{
			title: 'Birth Certificate',
			description: 'Your first official document.',
			iconName: 'certificate',
		},
		{
			title: 'ID Application',
			description: 'Prove your identity, simply.',
			iconName: 'id card outline',
		},
		{
			title: 'Scholarships',
			description: 'Fuel your dreams with education.',
			iconName: 'graduation',
		},
		{
			title: 'Visas',
			description: 'Travel beyond borders, hassle-free.',
			iconName: 'plane',
		},
	];

	return (
		<div className="streaming-cards">
			<Header as="h2" textAlign="center">
				Please select an application from below
			</Header>
			<Grid centered columns={5}>
				{applicationTopics.map((topic, index) => (
					<Grid.Column key={index}>
						<Card>
							<Card.Content>
								<Icon name={topic.iconName} size="huge" />
								<Card.Header>{topic.title}</Card.Header>
								<Card.Description>{topic.description}</Card.Description>
							</Card.Content>
							<Card.Content extra>
								<Button basic color="blue">
									Select
								</Button>
							</Card.Content>
						</Card>
					</Grid.Column>
				))}
			</Grid>
		</div>
	);
};

export default Applications;

import React, { useState } from 'react';
import {
	Accordion,
	Icon,
	Header,
	Form,
	TextArea,
	Button,
} from 'semantic-ui-react';

const FAQs = () => {
	const [activeIndex, setActiveIndex] = useState(-1);
	const [query, setQuery] = useState('');

	const faqItems = [
		{
			question: 'How can I apply for a scholarship?',
			answer:
				'You can apply for scholarships via our online application platform under the Scholarships section.',
		},
		{
			question: 'What documents are needed for ID Application?',
			answer:
				'You will need a birth certificate, proof of address, and a passport-sized photograph.',
		},
		{
			question: 'How do I report an emergency?',
			answer:
				'You can report emergencies via our emergency hotline or through the online portal.',
		},
	];

	const handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const newIndex = activeIndex === index ? -1 : index;
		setActiveIndex(newIndex);
	};

	const handleQueryChange = (e) => {
		setQuery(e.target.value);
	};

	return (
		<div>
			<Header as="h2" textAlign="center">
				Frequently Asked Questions
			</Header>
			<Accordion fluid styled>
				{faqItems.map((item, index) => (
					<div key={index}>
						<Accordion.Title
							active={activeIndex === index}
							index={index}
							onClick={handleClick}
						>
							<Icon name="dropdown" />
							{item.question}
						</Accordion.Title>
						<Accordion.Content active={activeIndex === index}>
							{item.answer}
						</Accordion.Content>
					</div>
				))}
			</Accordion>

			<Header as="h3" textAlign="center">
				Ask your Query
			</Header>
			<Form>
				<TextArea
					placeholder="Type your question here..."
					onChange={handleQueryChange}
				/>
				<Button primary style={{ marginTop: '10px' }}>
					Submit
				</Button>
			</Form>
		</div>
	);
};

export default FAQs;

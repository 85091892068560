import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Switch } from 'react-router-dom';

import { history } from './history';

import NavBar from './navbar/NavBar';
import HomePage from './homepage/HomePage';
import Streaming from './streaming/Streaming';
import AdminHomePage from './admin-homepage/AdminHomePage';
import InfoCenter from './Info-center/InfoCenter';
import Application from './apply/Application';
import FAQs from './faq/faq';
import { Container } from 'semantic-ui-react';

function App() {
	return (
		<BrowserRouter basename="/" history={history}>
			<div className="App container-content">
				<div className="background-image">
					<Container fluid>
						<NavBar />
						<br />
						<br />
						<br />
						<br />
						<br />

						<Switch>
							<Route path="/" exact component={HomePage} />
							<Route path="/streaming" exact component={Streaming} />
							<Route path="/admin" exact component={AdminHomePage} />
							<Route path="/info-center" exact component={InfoCenter} />
							<Route path="/apply" exact component={Application} />
							<Route path="/faqs" exact component={FAQs} />
							{/* Add more Routes here as needed */}
						</Switch>
					</Container>
				</div>
			</div>
		</BrowserRouter>
	);
}

export default App;

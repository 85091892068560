/**
 * Streaming.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useState } from 'react';
import {
	Card,
	Button,
	Icon,
	Modal,
	Embed,
	Container,
	Image,
} from 'semantic-ui-react';
import summitPreview from '../img/ict-summit.png';
import ICTVideo from '../img/summit.mov';

const Streaming = () => {
	const [playingAudio, setPlayingAudio] = useState(null);
	const [playingVideo, setPlayingVideo] = useState(null);
	const [videoModalOpen, setVideoModalOpen] = useState(false);

	const audioImg =
		'https://t4.ftcdn.net/jpg/01/67/05/93/360_F_167059387_uGWkpmaeQ4DAYnMY2YIMjwpz4cMW8Vft.jpg';

	const audioData = [
		{
			id: 1,
			title: 'Podcast Episode 1',
			description: 'This is a short description of podcast episode 1...',
			previewImage:
				'https://i.pinimg.com/originals/93/2f/d0/932fd00d9b43753061c47739f0cc777b.gif',
		},
		{
			id: 2,
			title: 'Podcast Episode 1',
			description: 'This is a short description of podcast episode 1...',
			previewImage:
				'https://i.pinimg.com/originals/93/2f/d0/932fd00d9b43753061c47739f0cc777b.gif',
		},
		{
			id: 4,
			title: 'Podcast Episode 1',
			description: 'This is a short description of podcast episode 1...',
			previewImage:
				'https://i.pinimg.com/originals/93/2f/d0/932fd00d9b43753061c47739f0cc777b.gif',
		},
		// more audio data
	];

	const videoData = [
		{
			id: 1,
			title: '7th NATIONAL ICT SUMMIT',
			description: 'This is a short description of video event 1...',
			previewImage: summitPreview,
			videoUrl: 'https://fb.watch/nATat1aB96/',
		},
		{
			id: 4,
			title: 'Covid 19 Learn',
			description: 'This is a short description of covid...',
			previewImage:
				'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsv9jvci7FEQwbusnciTWuEG5iiD0NzWZzoQ&usqp=CAU',
			videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
		},
		{
			id: 5,
			title: 'Watch Namibia at the olympics',
			description: 'Watch as the namibian team wins it',
			previewImage:
				'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT47ACXbjY1yEBWrl35QdZyhtNMchsYUmeL5g&usqp=CAU',
			videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
		},
		// more video data
	];

	return (
		<div className="streaming-container" style={{ width: '80%' }}>
			<div className="streaming-section">
				<h2>Audio/Podcasts</h2>
				<div className="streaming-cards">
					<Card.Group>
						{audioData.map((audio, index) => (
							<Card key={audio.id} color="red">
								<div
									className={playingAudio === audio.id ? 'waves-animation' : ''}
								>
									<Image
										key={index}
										src={
											playingAudio === audio.id ? audio.previewImage : audioImg
										}
										wrapped
										ui={true}
									/>
									<Card.Content>
										<Card.Header>{audio.title}</Card.Header>
										<Card.Description>{audio.description}</Card.Description>
									</Card.Content>
								</div>
								<Card.Content extra>
									<Button
										icon
										labelPosition="right"
										onClick={() =>
											setPlayingAudio(
												playingAudio === audio.id ? null : audio.id
											)
										}
									>
										{playingAudio === audio.id ? 'Pause' : 'Play'}
										<Icon name={playingAudio === audio.id ? 'pause' : 'play'} />
									</Button>
								</Card.Content>
							</Card>
						))}
					</Card.Group>
				</div>
			</div>

			<div className="streaming-section">
				<h2>Video Events</h2>
				<div className="streaming-cards">
					<Card.Group>
						{videoData.map((video, index) => (
							<Card key={video.id} color="blue">
								<Image key={index} src={video.previewImage} wrapped ui={true} />
								<Card.Content>
									<Card.Header>{video.title}</Card.Header>
									<Card.Description>{video.description}</Card.Description>
								</Card.Content>
								<Card.Content extra>
									<Button
										icon
										labelPosition="right"
										onClick={() => {
											setPlayingVideo(video.id);
											setVideoModalOpen(true);
										}}
									>
										Play
										<Icon name="play" />
									</Button>
								</Card.Content>
							</Card>
						))}
					</Card.Group>
				</div>
			</div>

			{/* Video Modal */}
			<Modal open={videoModalOpen} onClose={() => setVideoModalOpen(false)}>
				<Modal.Header>Now Playing: {playingVideo?.title}</Modal.Header>
				<Modal.Content>
					{/* <Embed
						id={playingVideo?.videoUrl}
						url="https://www.youtube.com/watch?v=DW_lmCPcutQ&pp=ygUPaWN0IHN1bW1pdCAyMDIz"
					/> */}

					<video width="800" height={'600'} controls>
						<source src={ICTVideo} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</Modal.Content>
			</Modal>
		</div>
	);
};

export default Streaming;

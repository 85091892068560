/**
 * InfoCenter.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React from 'react';
import { Accordion, Icon, List } from 'semantic-ui-react';

const ministries = [
	{
		title: 'Ministry of Health and Social Services',
		about:
			'Responsible for overseeing public health concerns, providing medical services, and promoting social well-being. This includes strategic planning and coordination with other stakeholders in the health and social sectors.',
		downloads: [
			{ title: 'Health Guide', file: 'health_guide.pdf' },
			{ title: 'COVID-19 Guidelines', file: 'covid_guidelines.pdf' },
			{ title: 'Nutrition Plan', file: 'nutrition_plan.pdf' },
			{ title: 'Mental Health Resources', file: 'mental_health.pdf' },
		],
		links: [
			{ title: 'Official Website', url: 'https://mohss.gov.na/' },
			{ title: 'Public Health Portal', url: 'https://publichealth.na/' },
			{ title: 'Emergency Services', url: 'https://emergency.na/' },
		],
	},
	{
		title: 'Ministry of Education, Arts and Culture',
		about:
			'Aims to provide inclusive, equitable, and quality education along with promoting lifelong learning opportunities. The ministry also nurtures artistic and cultural heritage.',
		downloads: [
			{ title: 'Education Policy', file: 'education_policy.pdf' },
			{ title: 'Arts and Culture Guide', file: 'arts_culture_guide.pdf' },
			{ title: 'School List', file: 'school_list.pdf' },
			{ title: 'Scholarship Programs', file: 'scholarship_programs.pdf' },
		],
		links: [
			{ title: 'Official Website', url: 'https://moeac.gov.na/' },
			{ title: 'Online Learning', url: 'https://elearning.na/' },
			{ title: 'School Admission Portal', url: 'https://admissions.na/' },
		],
	},
	{
		title: 'Ministry of Finance',
		about:
			'Responsible for the management of public finances, tax collection, and budget planning. The ministry plays a pivotal role in the economic development of the country.',
		downloads: [
			{ title: 'Tax Guide', file: 'tax_guide.pdf' },
			{ title: 'Budget Report', file: 'budget_report.pdf' },
			{ title: 'Financial Policies', file: 'financial_policies.pdf' },
			{ title: 'Investment Guide', file: 'investment_guide.pdf' },
		],
		links: [
			{ title: 'Official Website', url: 'https://mof.gov.na/' },
			{ title: 'Tax Portal', url: 'https://taxportal.na/' },
			{ title: 'Economic Reports', url: 'https://economicreports.na/' },
		],
	},
	{
		title: 'Ministry of Agriculture, Water and Forestry',
		about:
			'Focused on the sustainable development of agriculture and water resources. The ministry also oversees forestry and is responsible for food security.',
		downloads: [
			{ title: 'Agriculture Guide', file: 'agriculture_guide.pdf' },
			{ title: 'Water Conservation', file: 'water_conservation.pdf' },
			{ title: 'Forestry Policies', file: 'forestry_policies.pdf' },
			{ title: 'Food Security Report', file: 'food_security.pdf' },
		],
		links: [
			{ title: 'Official Website', url: 'https://mawf.gov.na/' },
			{ title: 'Water Resources', url: 'https://waterresources.na/' },
			{ title: 'Agricultural Research', url: 'https://agriresearch.na/' },
		],
	},
	{
		title: 'Ministry of Environment and Tourism',
		about:
			'Responsible for the conservation of natural resources, wildlife protection, and promoting sustainable tourism. The ministry aims to balance environmental protection with economic benefits.',
		downloads: [
			{ title: 'Tourism Guide', file: 'tourism_guide.pdf' },
			{ title: 'Wildlife Conservation', file: 'wildlife_conservation.pdf' },
			{ title: 'Environmental Policies', file: 'environmental_policies.pdf' },
			{ title: 'Climate Change Report', file: 'climate_change.pdf' },
		],
		links: [
			{ title: 'Official Website', url: 'https://met.gov.na/' },
			{ title: 'Tourism Portal', url: 'https://tourism.na/' },
			{ title: 'National Parks', url: 'https://nationalparks.na/' },
		],
	},
	{
		title: 'Ministry of Mines and Energy',
		about:
			'Oversees the exploration, extraction, and sustainable development of Namibia’s mineral and energy resources for the benefit of all Namibians.',
		downloads: [
			{ title: 'Mining Regulations', file: 'mining_regulations.pdf' },
			{ title: 'Energy Policies', file: 'energy_policies.pdf' },
			{ title: 'Safety Guidelines', file: 'safety_guidelines.pdf' },
			{ title: 'Resource Reports', file: 'resource_reports.pdf' },
		],
		links: [
			{ title: 'Official Website', url: 'https://mme.gov.na/' },
			{ title: 'Resource Portal', url: 'https://resources.na/' },
			{ title: 'Energy Efficiency', url: 'https://energyefficiency.na/' },
		],
	},
];

const InfoCenter = () => {
	const [activeIndex, setActiveIndex] = React.useState(-1);

	const handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const newIndex = activeIndex === index ? -1 : index;

		setActiveIndex(newIndex);
	};

	return (
		<div
			style={{ marginBottom: '20px', overflowY: 'auto' }}
			className="streaming-section"
		>
			{/* Large Centered Image */}
			<img
				src="https://imgv2-2-f.scribdassets.com/img/document/319003495/original/257a1982a0/1577955066?v=1"
				alt="Info Center"
				style={{ display: 'block', margin: 'auto', width: '900px' }}
			/>

			<div className="streaming-cards">
				{/* Ministries Accordion */}
				<Accordion styled>
					{ministries.map((ministry, index) => (
						<div key={index}>
							<Accordion.Title
								active={activeIndex === index}
								index={index}
								onClick={handleClick}
							>
								<Icon name="dropdown" />
								{ministry.title}
							</Accordion.Title>
							<Accordion.Content
								active={activeIndex === index}
								style={{ marginBottom: '20px', overflowY: 'auto' }}
							>
								<h3>About</h3>
								<p>{ministry.about}</p>
								{/* ... (existing About and Links sections) */}

								{/* Downloads Section */}
								<h3>Downloads</h3>
								<div className="downloads-list">
									{ministry.downloads.map((download, i) => (
										<div
											key={i}
											style={{ textAlign: 'center', margin: '10px' }}
										>
											<Icon name="file pdf outline" color="red" size="big" />
											<div>{download.title}</div>
										</div>
									))}
								</div>
								<h3>Important Links</h3>
								<List>
									{ministry.links.map((link, i) => (
										<List.Item key={i}>
											<a href={link.url}>{link.title}</a>
										</List.Item>
									))}
								</List>
							</Accordion.Content>
						</div>
					))}
				</Accordion>
			</div>
		</div>
	);
};

export default InfoCenter;
